@use "sass:math";

#Footer {
    color: var(--white);

    @media (min-width: $smartphone) {
        --size-logo-1: #{rem(20)};
        --size-logo-2: #{rem(24)};
        --size-logo-cuchillo: #{rem(48)};
        --padding: var(--padding-xxs);
    }

    @media (max-width: $smartphone) {
        --size-logo-1: #{rem(20)};
        --size-logo-2: #{rem(24)};
        --size-logo-cuchillo: #{rem(52)};
        --padding: var(--padding-xxs);
    }
}

#Footer {
    @include z-index($z-index-footer);

    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: var(--padding);
    display: flex;
    justify-content: space-between;

    display: none !important;

    > div {
        flex: 1 0 100%;
        display: flex;
        align-items: flex-end;

        gap: var(--padding-xxs);

        > div {
            margin-right: var(--padding-xxs)
        }

        a {
            @include font-sans();
            font-size: var(--font-size-base);
            text-decoration: none;

            &:nth-child(1) {
                color: var(--white);
                margin-right: var(--padding-s);

                @media (max-width: $smartphone) {
                    color: var(--white);
                }
            }

            @media (max-width: $smartphone) {
                &:nth-child(2) { display: none; }
                &:nth-child(3) { display: none; }
            }

            color: #333;
        }
    }

    ul {
        flex: 1 0 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        @media (max-width: $smartphone) {
            margin-bottom: -4px;
            margin-right: -4px;
        }
    }

    .logo-cuchillo {
        margin-right: 1.4em;

        > a {
            flex: 0 0 auto;
            position: relative;
            display: flex;
            align-items: flex-end;
            gap: .41em;
            color: var(--white);
            @include font-sans();
            font-size: var(--font-size-base);

            span {
                display: block;
                position: relative;
                top: .2em;
            }

            svg {
                position: relative;
                fill: currentColor;
                width: var(--size-logo-cuchillo);
                height: auto;
                top: -.1em;

                @media (max-width: $smartphone) {
                    top: -.1em;
                }
            }

            sup {
                position: absolute;
                top:.45em;
                right: -1.2em;
            }
        }
    }

    .logo-kd01 {
        > svg {
            display: block;
            height: var(--size-logo-1);
            width: auto;
            fill: currentColor;
        }
    }

    .logo-kd02 {
        > svg {
            display: block;
            height: var(--size-logo-2);
            width: auto;
            fill: currentColor;
        }
    }
}
